import { Button, Typography } from "@mui/material"
import { WALLU_INVITE_URL } from "../../config/urls"
import React from "react"

const NotOnThatServer = ({ header }: any) => {
  return (
    <div>
      {header}
      <Typography style={{ fontSize: '22px' }} className="text-red-400">
        The bot is not on that server.
      </Typography>
      <p>
        Please select the correct server, invite the bot to the server you want to manage or refresh the page.
      </p>
      <div className="mt-6">
        <Button
          href={WALLU_INVITE_URL}
          target={"_blank"}
          variant="contained"
        >Add to server</Button>
      </div>
    </div>
  )
}

export default NotOnThatServer
