import { Button, Link } from "@mui/material"
import { HOME_WEBSITE_URL, PLANS_WEBSITE_URL, SUPPORT_URL } from "../config/urls"
import React from "react"
import { getBillingPageURL, isPremium } from "./ManageSubscriptionButton"

import HomeIcon from '@mui/icons-material/Home'
import WhatsNewIcon from '@mui/icons-material/Newspaper'
import PremiumIcon from "@mui/icons-material/WorkspacePremium"
import useAppContext from "../context/useAppContext"
import { canVoteAgain } from "./VoteBotModal"
import VoteIcon from '@mui/icons-material/MonetizationOn'

export const UsefulLinks = ({ setOpenVoteModal }: any) => {

  return (
    <div className="flex flex-row gap-4 text-xs lg:gap-10 lg:text-base flex-wrap">
      <Link href={SUPPORT_URL} className="flex" style={{ textDecoration: 'none' }} target="_blank">
        <DiscordLogo/>
        <span className="ml-1 text-gray-400">Support</span>
      </Link>
      <PlansOrManagePlan/>
      <Link href={HOME_WEBSITE_URL} style={{ textDecoration: 'none' }}>
        <HomeIcon fontSize="medium" className="text-gray-300"/>
        <span className="ml-1 text-gray-400">Home</span>
      </Link>
      <Link href={SUPPORT_URL} style={{ textDecoration: 'none' }} target="_blank">
        <WhatsNewIcon fontSize="medium" className="text-gray-300"/>
        <span className="ml-1 text-gray-400">What's new?</span>
      </Link>
      {canVoteAgain() && (
        <Button
          className="mt-4" variant="outlined" color="success"
          onClick={() => setOpenVoteModal(true)}
          startIcon={<VoteIcon/>}
        >
          Claim Free Credits
        </Button>
      )}
    </div>
  )
}

export const PlansOrManagePlan = () => {

  const { guild, plan, billing } = useAppContext()

  return isPremium(plan) || billing?.stripe ? (
    <Link href={getBillingPageURL(guild?.id)} style={{ textDecoration: 'none' }}>
      <PremiumIcon fontSize="medium" className="text-gray-300"/>
      <span className="ml-1 text-gray-400">Manage Billing</span>
    </Link>
  )
    :
    (
      <Link href={PLANS_WEBSITE_URL} style={{ textDecoration: 'none' }} target="_blank">
        <PremiumIcon fontSize="medium" className="text-gray-300"/>
        <span className="ml-1 text-gray-400">Our Plans</span>
      </Link>
    )
}

export const DiscordLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36" height="22" width="22">
    <g id="图层_2" data-name="图层 2">
      <g id="Discord_Logos" data-name="Discord Logos">
        <g id="Discord_Logo_-_Large_-_White" data-name="Discord Logo - Large - White">
          <path className="fill-gray-300"
            d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"/>
        </g>
      </g>
    </g>
  </svg>
)
