import client, { NLP_API_BASE_URL } from "../config/axios"
import { FAQ } from "../model/FAQ"
import debug from "../config/debug"
import { Config } from "../model/Config"
import { Suggestion } from "../model/Suggestion"
import { ServerPlan } from "../model/ServerPlan"
import { TokenUsageData } from "../model/TokenUsageData"
import { Billing } from "../model/Billing"

export type ServerDataResponse = {
  config: Config
  faqs: FAQ[]
  plan: ServerPlan
  billing: Billing
  token_data: TokenUsageData
  meta: {
    show_get_started?: boolean
    seem_multilingual?: boolean
    seems_cloudflare_blocked?: boolean
  } | null
}

export type SuggestionsResponse = { suggestions: Suggestion[] }

export type TaskResponse = {
  task_id: string
}

export type ImportedFAQ = {
  id: any
  selected?: boolean;
  question: string, answer: string
  can_already_answer: boolean
}

export type TaskState = "SUCCESS" | "FAILURE" | "PENDING" | "RETRY" | "STARTED" | "REVOKED"

export type TaskPollResponse = {
  /**
   * If it has been completed (SUCCESS or FAILURE)
   */
  ready: boolean
  result?: ImportedFAQ[]
  state: TaskState,
  current: number,
  total: number,
  error?: string
}


export async function getServerData(guildId: string): Promise<ServerDataResponse> {
  const data = await client.get("/server/" + guildId)
  debug(`guild${guildId} data: ${data.data}`)
  return data.data
}

export async function saveFAQs(guildId: string, faqs: FAQ[]): Promise<ServerDataResponse> {
  const res = await client.post(`/faqs/${guildId}`, faqs)
  return res.data
}

export async function saveConfig(guildId: string, config: Partial<Config>) {
  return client.post(`/config/${guildId}`, config)
}

export async function fetchSuggestions(guildId: string): Promise<SuggestionsResponse> {
  const res = await client.get(`/public-nlp-api/suggestions/${guildId}`, {
    baseURL: NLP_API_BASE_URL
  })
  return res.data
}

// A bit over-engineering, but it's useful
export async function estimateImport(content: string): Promise<{ estimated_token_usage: number }> {
  const res = await client.post(`/tasks/estimate/extract-faqs`, { content })
  return res.data
}

export async function extractFAQs(guildId: string, content: string): Promise<TaskResponse> {
  const res = await client.post(`/tasks/execute/extract-faqs/${guildId}`, { content })
  return res.data
}

export async function pollExtractedFAQs(taskId: string): Promise<TaskPollResponse> {
  const res = await client.get(`/tasks/status/extract-faqs/${taskId}`)
  return res.data
}
