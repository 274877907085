import useDocumentProgress from "../../util/hooks/useDocumentProgress"
import ErrorIcon from "@mui/icons-material/Error"
import WarningIcon from "@mui/icons-material/Warning"
import InfoIcon from "@mui/icons-material/Info"
import { useCreditUsage } from "./CreditUsageBar"
import { ServerDataResponse } from "../../service/faqService"

type TopNotificationBarProps = { serverData: null | ServerDataResponse }

export default function TopNotificationBar({ serverData }: TopNotificationBarProps) {

  const { totalProgress, importProgress } = useDocumentProgress()
  const documentsLeftToImport = Object.values(importProgress).filter(progress => !progress.ready).length
  const { percent: creditUsagePercent } = useCreditUsage()

  if (!serverData) return null

  const status: {
    text: string
    type: "info" | "warning" | "error"
  } = {
    text: "",
    type: "info"
  }

  if (creditUsagePercent >= 99) {
    status.type = "error"
    status.text = "You have run out of credits."
  } else if (creditUsagePercent > 90) {
    status.type = "warning"
    status.text = `You are running out of credits soon (${creditUsagePercent}% used)`
  } else if (totalProgress < 1 && totalProgress > 0) {
    if (documentsLeftToImport > 0) {
      status.type = "info"
      // Show both the import progress and the document processing progress separately
      status.text = `Processing document content: ${Math.floor(totalProgress * 100)}% complete, ${documentsLeftToImport} left to import`
    } else {
      status.type = "info"
      status.text = `Processing document content: ${Math.floor(totalProgress * 100)}% complete`
    }
  } else if (documentsLeftToImport > 0) {
    status.type = "info"
    status.text = `Importing ${documentsLeftToImport} document(s)...`
  }
  if (serverData?.meta?.seems_cloudflare_blocked) {
    status.type = "error"
    status.text = "Seems like some documents are blocked by Cloudflare. Please disable Cloudflare's bot protection, whitelist Wallu or contact us for help"
  }
  else if (serverData?.meta?.seem_multilingual && serverData?.config?.model !== "multilingual") {
    status.type = "warning"
    status.text = "Your documents/FAQ do not seem to be in english, but your model is not set to multilingual. You can change this in the settings page"
  }

  if (!status.text) return null

  const textColor = {
    info: "text-gray-200",
    warning: "text-yellow-500",
    error: "text-red-500"
  }[status.type]

  const muiIcon = {
    info: <InfoIcon className="text-blue-300"/>,
    warning: <WarningIcon className="text-yellow-500"/>,
    error: <ErrorIcon className="text-red-500"/>
  }[status.type]

  return (
    <div className={`w-full p-2 text-center fixed z-10 top-0 font-bold left-[50%] transform translate-x-[-50%] bg-gray-800`}>
      <div className={`flex justify-center items-center ${textColor}`}>
        <span className="mr-2">
          {muiIcon}
        </span>
        {status.text}
      </div>
    </div>
  )
}
